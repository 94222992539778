

export const categoryMenu = [
    {
      key : 'HEALTH_HOBBY',
      menuName: 'HEALTH_HOBBY',
      imgPath : 'test'
    },
  {
    key : 'ART_POP_CULTURE',
    menuName: 'ART_POP_CULTURE',
    imgPath : 'test'
  },
    {
        key : 'HISTORY',
        menuName: 'HISTORY',
        imgPath : 'test'
    },
    {
        key : 'COMICS',
        menuName: 'COMICS',
        imgPath : 'test'
    },
    {
        key : 'TRAVEL',
        menuName: 'TRAVEL',
        imgPath : 'test'
    },
    {
        key : 'ESSAY',
        menuName: 'ESSAY',
        imgPath : 'test'
    },
    {
        key : 'CHILDREN',
        menuName: 'CHILDREN',
        imgPath : 'test'
    }

];

export const statusMenu = [
    {
        key : 'EXCELLENT',
        menuName: 'EXCELLENT',
        imgPath : 'test'
    },
    {
        key : 'GOOD',
        menuName: 'GOOD',
        imgPath : 'test'
    },
    {
        key : 'ABOVE_AVERAGE',
        menuName: 'ABOVE_AVERAGE',
        imgPath : 'test'
    },
    {
        key : 'AVERAGE',
        menuName: 'AVERAGE',
        imgPath : 'test'
    },
    {
        key : 'BELOW_AVERAGE',
        menuName: 'BELOW_AVERAGE',
        imgPath : 'test'
    },
    {
        key : 'POOR',
        menuName: 'POOR',
        imgPath : 'test'
    }

];



export const rentalMenu = [
    {
        key : 'DELIVERY',
        menuName: 'DELIVERY',
        imgPath : 'test'
    },
    {
        key : 'FACE_TO_FACE',
        menuName: 'FACE_TO_FACE',
        imgPath : 'test'
    }
];
